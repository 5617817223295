import axios from "axios";

export const createANotification = async (notification, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/notification`, notification, {
    headers: {
      authtoken,
    },
  });

export const getNotification = async (slug) =>
  await axios.get(`${process.env.REACT_APP_API}/notification/${slug}`);

export const removeNotification = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/notification/${slug}`, {
    headers: {
      authtoken,
    },
  });

export const getNotifications = async () =>
  await axios.get(`${process.env.REACT_APP_API}/get-all-notifications`);
