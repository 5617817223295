import axios from "axios";

export const getCategories = async (type) =>
  await axios.get(`${process.env.REACT_APP_API}/categories?type=${type}`);

export const getCategory = async (slug) =>
  await axios.get(`${process.env.REACT_APP_API}/category/${slug}`);

export const removeCategory = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/category/${slug}`, {
    headers: {
      authtoken,
    },
  });

export const updateCategory = async (slug, category, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/category/${slug}`, category, {
    headers: {
      authtoken,
    },
  });
  

export const createCategory = async (category, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/category`, category, {
    headers: {
      authtoken,
    },
  });

export const getCategorySubs = async (_id) =>
  await axios.get(`${process.env.REACT_APP_API}/category/subs/${_id}`);

export const getSubsSecond = async (_id) =>
  await axios.get(`${process.env.REACT_APP_API}/category/subs/secondSubs/${_id}`);

export const getPinCodes = async (id) =>
  await axios.get(`https://api.postalpincode.in/pincode/${id}`);

