import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from 'antd';


const { Panel } = Collapse;

const AdminNav = () => (
  <nav>
    <br />
    <Collapse className="nav flex-column" defaultActiveKey={['1']}>
      <Panel header="Orders" key="1">
        <Link to="/admin/dashboard" className="nav-link">
          Dashboard
        </Link>
        <li className="nav-item">
          <Link to="/admin/sale" className="nav-link">
            Sale
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/returns/all" className="nav-link">
            Returns
          </Link>
        </li>
      </Panel>

      <Panel header="Product" key="2">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/admin/product" className="nav-link">
              Product
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/products" className="nav-link">
              Products
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/category" className="nav-link">
              Category
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/sub" className="nav-link">
              Sub Category
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/secondSub" className="nav-link">
              Second Sub Category
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/brands" className="nav-link">
              Brands
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/filter/age" className="nav-link">
              Filters
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/offer" className="nav-link">
              Tags
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/deals" className="nav-link">
              Deals
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/create-country" className="nav-link">
              Country
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/variation" className="nav-link">
              Create Variation
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/variation/all" className="nav-link">
              List All Variation
            </Link>
          </li>
          <li className="nav-item">  <Link to="/admin/create-amazon" className="nav-link">
            Amazon Item
          </Link>
          </li>

          <li className="nav-item">  <Link to="/admin/update-amazon" className="nav-link">
            Amazon Pictures
          </Link>
          </li>
        </ul>
      </Panel>

      <Panel header="Inventory Management" key="3">
        <Link to="/admin/inventory?store=east-of-kailash" className="nav-link">
          Inventory
        </Link>
        <Link to={`/storecharge/inventory-health?store=east-of-kailash`} className="nav-link">
          Inventory Health
        </Link>
        <Link to={`/storecharge/price-health?store=east-of-kailash`} className="nav-link">
          Price Health
        </Link>
        <Link to={`/storecharge/brand-health?store=east-of-kailash`} className="nav-link">
          Brand Order
        </Link>
        <Link to="/admin/variety" className="nav-link">
          Variety
        </Link>
        <Link to="/admin/ai/dashboard" className="nav-link">
          Pricing
        </Link>

      </Panel>


      <Panel header="Marketing" key="4">
        <ul className="nav flex-column">
          <li className="nav-item">
            <Link to="/admin/carts" className="nav-link">
              Abandoned Carts
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/gift" className="nav-link">
              Gifts
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/coupon" className="nav-link">
              Coupon
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/banner" className="nav-link">
              Recipe Management
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/recipe-tag" className="nav-link">
              Recipe Tags
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/all-recipes" className="nav-link">
              All Recipes
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/shipping-banner" className="nav-link">
              Shipping
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/create-banner" className="nav-link">
              Banners
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/super-coupon" className="nav-link">
              Super Coupons
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/notifications" className="nav-link">
              Notifications
            </Link>
          </li>

          <li className="nav-item">
            <Link to="/admin/create-blog" className="nav-link">
              Create Blogs
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/images" className="nav-link">
              Images
            </Link>
          </li>
          <li className="nav-item">
            <Link to="/admin/mkt/integration" className="nav-link">
              Integrations
            </Link>
          </li>
        </ul>
      </Panel>

      <Panel header="Extras" key="5">


        <li className="nav-item">
          <Link to="/admin/users" className="nav-link">
            Users
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/brand-discount" className="nav-link">
            Brand Discount
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/admin/supplier-add" className="nav-link">
            Supplier
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/employee" className="nav-link">
            Employees
          </Link>
        </li>

        <li className="nav-item">
          <Link to="/admin/location" className="nav-link">
            Point History
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/create-store" className="nav-link">
            Stores
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/company-create" className="nav-link">
            Companies
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/storecharge/price-tag" className="nav-link">
            Price Tags
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/ai/dashboard" className="nav-link">
            AI
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/admin/tax-create" className="nav-link">
            Tax
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/exhibition-list" className="nav-link">
            Exhibition
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/user/password" className="nav-link">
            Password
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/purchase/purcase-dashboard" className="nav-link">
            Purchase Dashboard
          </Link>
        </li>
        <li className="nav-item">
          <Link to="/user/dashboard" className="nav-link">
            User Dashboard
          </Link>
        </li>
      </Panel>

    </Collapse>

  </nav>
);

export default AdminNav;
