import React, { useEffect, useState, lazy, Suspense } from "react";
import { Switch, Route, useLocation, Redirect, useHistory } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { auth } from "./firebase";
import { useDispatch } from "react-redux";
import { currentUser } from "./functions/auth";
import { LoadingOutlined } from "@ant-design/icons";
import secondSubCreate from "./pages/admin/secondSub/secondSubCreate";
import ReactGA from "react-ga";
import TabBar from "./components/TabBar/TabBar";
import HeaderNavigation from "./components/BlackNavigation/HeaderNavigation";
import { createStore } from "redux";
import { Modal, message, Button } from 'antd';
import useAutoLogout from './components/useAutoLogout'
import 'antd/dist/antd.css';
import './components/nav/HeaderCss/header.css'
import '../node_modules/react-quill/dist/quill.snow.css';

// using lazy
const Login = lazy(() => import("./pages/auth/Login"));
const Register = lazy(() => import("./pages/auth/Register"));
const Home = lazy(() => import("./pages/Home"));
const NewHome = lazy(() => import("./pages/NewHome"));
const Header = lazy(() => import("./components/nav/Header"));
const SideDrawer = lazy(() => import("./components/drawer/SideDrawer"));
const CouponSideDrawer = lazy(() => import("./components/drawer/SideCouponDrawer"));
const Notification = lazy(() => import('./components/Notification/Notification'));
const RegisterComplete = lazy(() => import("./pages/auth/RegisterComplete"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));
const History = lazy(() => import("./pages/user/History"));
const Dashboard = lazy(() => import("./pages/user/Dashboard"));
const CardSaved = lazy(() => import("./pages/user/Payment"));
const UserRoute = lazy(() => import("./components/routes/UserRoute"));
const AdminRoute = lazy(() => import("./components/routes/AdminRoute"));
const AccountantRoute = lazy(() => import("./components/routes/AccountantRoute"));
const StoreChargeRoute = lazy(() => import("./components/routes/StoreChargeRoute"));
const CashierRoute = lazy(() => import("./components/routes/CashierRoute"));
const LogisticRoute = lazy(() => import("./components/routes/LogisticRoute"));
const DriverRoute = lazy(() => import("./components/routes/DriverRoute"));
const Password = lazy(() => import("./pages/user/Password"));
const Wishlist = lazy(() => import("./pages/user/Wishlist"));
const UserTransaction = lazy(() => import("./pages/user/UserTransaction"));
const Confirmation = lazy(() => import("./pages/Confirmation"))
const AdminDashboard = lazy(() => import("./pages/admin/AdminDashboard"));
const CategoryCreate = lazy(() =>
  import("./pages/admin/category/CategoryCreate")
);
const CategoryUpdate = lazy(() =>
  import("./pages/admin/category/CategoryUpdate")
);
const welcome = lazy(() => import("./components/Address/Welcome"))
const UsersPage = lazy(() => import("./pages/admin/extra/Users"));
const SalePage = lazy(() => import("./pages/admin/Sale"));
const CartsPage = lazy(() => import("./pages/admin/extra/AbandonedCarts"));
const LocationPage = lazy(() => import("./pages/admin/location/Location"));
const brandDiscount = lazy(() => import("./pages/admin/extra/BrandDiscount"));
const SubCreate = lazy(() => import("./pages/admin/sub/SubCreate"));
const SubUpdate = lazy(() => import("./pages/admin/sub/SubUpdate"));
const CountryUpdate = lazy(() => import("./pages/admin/country/UpdateCountry"));
const SecondSubCreate = lazy(() => import("./pages/admin/secondSub/secondSubCreate"));
const brandCreate = lazy(() => import("./pages/admin/brands/brandCreate"));
const dealCreate = lazy(() => import("./pages/admin/deal/CreateDeal"));
const dealUpdate = lazy(() => import("./pages/admin/deal/EditDeal"));
const bannerCreate = lazy(() => import("./pages/admin/banner/CreateBanner"))
const createBanner = lazy(() => import("./pages/admin/banner/BannerCreate"))
const offerCreate = lazy(() => import("./pages/admin/offer/CreateOffer"));
const tagCreate = lazy(() => import("./pages/admin/recipeTag/CreateRTag"));
const ProductCreate = lazy(() => import("./pages/admin/product/ProductCreate"));
const GiftCreate = lazy(() => import("./pages/admin/gift/GiftCreate"));
const AllProducts = lazy(() => import("./pages/admin/product/AllProducts"));
const ProductUpdate = lazy(() => import("./pages/admin/product/ProductUpdate"));
const brandUpdate1 = lazy(() => import("./pages/admin/brands/BrandUpdated"));
const OrderUpdate = lazy(() => import("./components/order/OrderView"));
const Product = lazy(() => import("./pages/Product"));
const CategoryHome = lazy(() => import("./pages/category/CategoryHome"));
const SubHome = lazy(() => import("./pages/sub/SubHome"));
const FlyerHome = lazy(() => import("./pages/sub/FlyerHome"));
const BrandHome = lazy(() => import("./pages/sub/BrandHome"));
const CountryHome = lazy(() => import("./pages/sub/CountryHome"))
const Shop = lazy(() => import("./pages/Shop"));
const Cart = lazy(() => import("./pages/Cart"));
const Checkout = lazy(() => import("./pages/Checkout"));
const Flyer = lazy(() => import("./pages/Flyer/Flyer"))
const FlyerListings = lazy(() => import("./pages/Flyer/FlyerListings"))
const CreateCouponPage = lazy(() =>
  import("./pages/admin/coupon/CreateCouponPage")
);
const CustomerManagement = lazy(() => import("./pages/admin/customer/CustomerManagement"));
const Payment = lazy(() => import("./pages/Payment"));
const Footer = lazy(() => import("./components/Footer/footer"));
const Terms = lazy(() => import("./components/Footer/TermsService"));
const Return = lazy(() => import("./components/Footer/ReturnPolicy"));
const Listing = lazy(() => import("./pages/ListingPage/Listing"))
const ProduceAisle = lazy(() => import("./pages/AisleSection/ProduceAisle"))
const SubAisle = lazy(() => import("./pages/AisleSection/SubAisle"))
const NewCustomer = lazy(() => import("./pages/ExtraPages/NewCustomer"))
const AboutUs = lazy(() => import("./pages/ExtraPages/AboutUs"))
const Navbar = lazy(() => import("./components/nav/Navbar"));
const RewardPage = lazy(() => import("./pages/Extra/MyReward"));
const OptimaContest = lazy(() => import("./pages/Extra/OptimaContest"));
const Covid19 = lazy(() => import("./pages/ExtraPages/Covid19"));
const Plasma = lazy(() => import("./pages/ExtraPages/Plasma"));
const Career = lazy(() => import("./pages/ExtraPages/Career"));
const dealPage = lazy(() => import("./pages/DealPage"));
const referralPage = lazy(() => import("./pages/Extra/ReferralPage"));
const recipePage = lazy(() => import("./pages/recipe/MainRecipePage"))
const recipeTagPage = lazy(() => import("./pages/recipe/RecipeDetail"))
const recipeTagEditPage = lazy(() => import("./pages/admin/recipeTag/UpdateRTag"))
const recipeItemDetail = lazy(() => import("./pages/recipe/RecipeItemDetail"))
const recipeEdit = lazy(() => import("./pages/admin/recipeTag/RecipeEdit"))
const recipeUpdate = lazy(() => import("./pages/admin/recipeTag/UpdateRecipe"))
const storeCreate = lazy(() => import("./pages/admin/store/CreateStore"))
const storeEdit = lazy(() => import("./pages/admin/store/StoreEdit"))
const bannerUpdate = lazy(() => import("./pages/admin/banner/UpdateBanner"))
const DriverDashboard = lazy(() => import("./pages/driver/DriverView"))
const bulkStock = lazy(() => import("./pages/admin/stock/BulkStockUploader"))
const bestSellerPage = lazy(() => import("./components/home/BestSellerPage"))
const blackjack = lazy(() => import('./pages/Games/BlackJack/Blackjack'))
const Mines = lazy(() => import('./pages/Games/Mines/Mines'));
const Plinko = lazy(() => import('./pages/Games/Plinko/PlinkoGamePage'));
const GamePage = lazy(() => import('./pages/Games/Game'));

const privacy = lazy(() => import('./components/Footer/PrivacyPolicy'))
const superCoupon = lazy(() => import('./pages/admin/superCoupon/SuperCoupon'))
const superCouponHome = lazy(() => import('./pages/SuperHome/SuperCouponHome'))
const superCouponAnalytics = lazy(() => import('./pages/admin/superCoupon/SuperCouponAnalytics'))
const countryCreate = lazy(() => import('./pages/admin/country/CreateCountry'))
const passportView = lazy(() => import('./pages/Passport/PassportView'))
const businessDelivery = lazy(() => import("./pages/bulk/BusinessDelivery"))
const bulkStore = lazy(() => import('./pages/bulk/BulkStore'))
const bulkListing = lazy(() => import("./pages/bulk/BulkHome"))
const bulkCategory = lazy(() => import('./pages/bulk/BulkCategory'))
const shippingHome = lazy(() => import('./pages/Shipping/ShippingHome'))
const slotGame = lazy(() => import("./pages/Games/Slot/SlotPage"))
const shippingBanner = lazy(() => import("./pages/admin/banner/ShippingBannerCreate"))
const shippingBannerUpdate = lazy(() => import('./pages/admin/banner/ShippingBannerUpdate'))
const giftUpdate = lazy(() => import('./pages/admin/gift/GiftUpdate'))
const storeCharge = lazy(() => import("./pages/AcessLevels/StoreCharge/StoreCharge"))
const driverPage = lazy(() => import("./pages/AcessLevels/Driver/Driver"))
const employeePage = lazy(() => import('./pages/admin/Employees/Employee'))
const employeeEdit = lazy(() => import('./pages/admin/Employees/EmployeesEdit'))
const supplier = lazy(() => import('./pages/Extra/SupplierDiversity'))
const notificationCreate = lazy(() => import('./pages/admin/Notification/CreateNotification'))
const priceTagCreate = lazy(() => import("./pages/PiceTag/CreatePriceTag"))
const seller = lazy(() => import("./pages/seller/Seller"))
const categoryAll = lazy(() => import('./pages/category/CategoryAll'))
const TypePage = lazy(() => import('./pages/filter/Type'));
const AgePage = lazy(() => import('./pages/filter/Age'));
const BIngredientPage = lazy(() => import('./pages/filter/BIngredient'));
const ColorPage = lazy(() => import('./pages/filter/Color'));
const DietPage = lazy(() => import('./pages/filter/Diet'));
const FeaturesPage = lazy(() => import('./pages/filter/Features'));
const ForUsePage = lazy(() => import('./pages/filter/ForUse'));
const GenderPage = lazy(() => import('./pages/filter/Gender'));
const HairTypePage = lazy(() => import('./pages/filter/HairType'));
const MaterialPage = lazy(() => import('./pages/filter/Material'));
const SchoolAgePage = lazy(() => import('./pages/filter/SchoolAge'));
const WatchDisplayPage = lazy(() => import('./pages/filter/WatchDisplay'));
const FilterEdit = lazy(() => import('./pages/filter/FilterEdit'));
const customerSupport = lazy(() => import('./components/Footer/Support'));
const refundPage = lazy(() => import('./components/Footer/Refund'));
const PiceAi = lazy(() => import('./pages/admin/AI/PricesAi'));
const ProductsAi = lazy(() => import('./pages/admin/AI/ProductsAi'));
const DashboardAi = lazy(() => import('./pages/admin/AI/DashboardAi'));
const CaseColorPage = lazy(() => import('./pages/filter/CaseColor'));
const DialColorPage = lazy(() => import('./pages/filter/DialColor'));
const DialShapePage = lazy(() => import('./pages/filter/DialShape'));
const MovementPage = lazy(() => import('./pages/filter/Movement'));
const OccasionPage = lazy(() => import('./pages/filter/Occasion'));
const StrapColorPage = lazy(() => import('./pages/filter/StrapColor'));
const StorePos = lazy(() => import('./pages/AcessLevels/StoreCharge/StorePos'));
const StorePoductList = lazy(() => import('./pages/AcessLevels/StoreCharge/Product/ProductList'));
const supplierAdd = lazy(() => import('./pages/admin/Supplier/SupplierCreate'));
const supplierEdit = lazy(() => import('./pages/admin/Supplier/SupplierEdit'));
const StorePoductAdd = lazy(() => import('./pages/AcessLevels/StoreCharge/Product/AddStoreProduct'));
const StorePurchaseAdd = lazy(() => import('./pages/AcessLevels/StoreCharge/Purchase/AddPurchase'));
const taxCreate = lazy(() => import('./pages/admin/Supplier/TaxCreate'));
const storePurchaseList = lazy(() => import('./pages/AcessLevels/StoreCharge/Purchase/AllPurchases'));
const StoreSupplierList = lazy(() => import('./pages/AcessLevels/StoreCharge/Supplier/SupplierList'))
const StoreSupplierCreate = lazy(() => import('./pages/AcessLevels/StoreCharge/Supplier/SupplierCreate'))
const DiwaliPage = lazy(() => import('./pages/Flyer/Diwali'))
const StoreOrders = lazy(() => import('./pages/AcessLevels/StoreCharge/StoreOrders'))
const findStoreCustomers = lazy(() => import('./pages/AcessLevels/StoreCharge/Users/StoreCustomers'))
const StoreInventory = lazy(() => import('./pages/AcessLevels/StoreCharge/Inventory/InventoryManagement'))
const PurchaseOrderCreate = lazy(() => import('./pages/AcessLevels/StoreCharge/Purchase/AddPurchaseOrder'));
const PurchaseOrderEdit = lazy(() => import('./pages/AcessLevels/StoreCharge/Purchase/EditPurchase'));
const TransferCreate = lazy(() => import('./pages/AcessLevels/StoreCharge/Transfer/TranferCreate'));
const TransferAllStore = lazy(() => import('./pages/AcessLevels/StoreCharge/Transfer/AllTransfers'));
const TransferRequests = lazy(() => import('./pages/AcessLevels/StoreCharge/Transfer/TransferRequest'));
const TransferEdit = lazy(() => import('./pages/AcessLevels/StoreCharge/Transfer/EditTransfer'));
const OrderEdit = lazy(() => import('./pages/AcessLevels/StoreCharge/Inventory/OrderEdit'));
const CashierDashboard = lazy(() => import('./pages/AcessLevels/Cashier/CashierDashboard'));
const LogisticDashboard = lazy(() => import('./pages/AcessLevels/Logistic/LogisticDashboard'));
const SupplierDashboard = lazy(() => import('./pages/AcessLevels/Logistic/SupplierDashboard'));
const ProductDetailDashboard = lazy(() => import('./pages/AcessLevels/Logistic/ProductDashboard'));
const SaleDashboard = lazy(() => import('./pages/AcessLevels/StoreCharge/SaleDashboard'));
const CreateExpense = lazy(() => import('./pages/AcessLevels/StoreCharge/Expenses/CreateExpense'));
const ExpenseList = lazy(() => import('./pages/AcessLevels/StoreCharge/Expenses/ExpenseList'));
const CreateSaleReturn = lazy(() => import('./pages/AcessLevels/StoreCharge/Return/AddSaleReturn'));
const CreatePurchaseReturn = lazy(() => import('./pages/AcessLevels/StoreCharge/Return/AddPurchaseReturn'));
const ReturnList = lazy(() => import('./pages/AcessLevels/StoreCharge/Return/ReturnList'));
const AccountantDashboard = lazy(() => import('./pages/AcessLevels/Accounts/AccountsDashboard'));
const AccountantGroupCreate = lazy(() => import('./pages/AcessLevels/Accounts/Master/CreateGroup'));
const AccountantGroupSubCreate = lazy(() => import('./pages/AcessLevels/Accounts/Master/CreateSupGroup'));
const AccountantBankVoucher = lazy(() => import('./pages/AcessLevels/Accounts/Voucher/BankVoucer'));
const companyCreate = lazy(() => import('./pages/admin/Company/CreateCompany'));
const franchisePage = lazy(() => import('./pages/ExtraPages/Franchise'));
const blogCreate = lazy(() => import('./pages/AcessLevels/Marketing/Blogs/CreateBlog'));
const TagUpdate = lazy(() => import('./pages/admin/offer/UpdateTag'));
const inventoryDetail = lazy(() => import('./pages/admin/Inventory/Inventory'));
const varietyDetail = lazy(() => import('./pages/admin/Inventory/Variety'));
const PricingDetail = lazy(() => import('./pages/admin/Inventory/Pricing'));
const GstReport = lazy(() => import('./pages/AcessLevels/Accounts/GST/GstReport'));
const Gstr2 = lazy(() => import('./pages/AcessLevels/Accounts/GST/Gstr2'));
const Gstr3 = lazy(() => import('./pages/AcessLevels/Accounts/GST/Gstr3'));
const LedgerPage = lazy(() => import('./pages/AcessLevels/Accounts/Ledgers/LedgerPage'));
const LedgerDetail = lazy(() => import('./pages/AcessLevels/Accounts/Ledgers/LedgerDetail'));
const PlacementPage = lazy(() => import('./pages/AcessLevels/StoreCharge/Placement/Placement'));
const PlacementSub = lazy(() => import('./pages/AcessLevels/StoreCharge/Placement/PlacementSub'));
const pigeonHome = lazy(() => import('./pages/AcessLevels/StoreCharge/Barcode/Barcode'));
const ExpiryDashboard = lazy(() => import('./pages/AcessLevels/Logistic/ExpiryDashboad'));
const AllLedgers = lazy(() => import('./pages/AcessLevels/Accounts/Ledgers/AllLedgers'));
const discreetPage = lazy(() => import('./pages/Extra/Discreet'));
const CreateVariation = lazy(() => import('./pages/admin/Variation/CreateVariation'));
const businessPos = lazy(() => import('./pages/AcessLevels/StoreCharge/Sale/B2bPoss'));
const ExhibitionPage = lazy(() => import('./pages/Extra/Exhibition'));
const ExhibitionList = lazy(() => import('./pages/Extra/ExhibitionList'));
const Health = lazy(() => import('./pages/admin/Inventory/Health'));
const Price = lazy(() => import('./pages/admin/Inventory/Price'));
const BrandPage = lazy(() => import('./pages/admin/Inventory/BrandPage'));
const BrandStock = lazy(() => import('./pages/admin/Inventory/BrandStock'));
const VariationList = lazy(() => import('./pages/admin/Variation/VariationList'));
const VariationEdit = lazy(() => import('./pages/admin/Variation/EditVariation'));
const ImagePage = lazy(() => import('./pages/admin/ImageScreen/ImageScreen'));
const AmazonPage = lazy(() => import('./pages/admin/product/AmazonProduct'));
const AmazonPics = lazy(() => import('./pages/admin/product/AmazonPictures'));
const userReturn = lazy(() => import('./pages/user/Returns'));
const userReturnList = lazy(() => import('./pages/user/ReturnList'));
const ReturnAdmin = lazy(() => import('./pages/admin/Returns/Return'));
const GuestCheckout = lazy(() => import('./pages/GuestCheckout'));
const Integration = lazy(() => import('./pages/admin/Marketing/Integrartion'));
const Instagram = lazy(() => import('./pages/admin/Marketing/Instagram'));
const IntegrationList = lazy(() => import('./pages/admin/Marketing/IntegrationList'));
const BomDashboard = lazy(() => import('./pages/AcessLevels/Bom/BomDashboard'));
const BomStock = lazy(() => import('./pages/AcessLevels/Bom/Stock'));
const BomRawmaterial = lazy(() => import('./pages/AcessLevels/Bom/RawMaterialPage'));
const BomFinishedMaterial = lazy(() => import('./pages/AcessLevels/Bom/FinishedGoodsPage'));
const FixedAssetsPage = lazy(() => import('./pages/AcessLevels/Bom/FixedAssetsPage'));
const SalesmanPage = lazy(() => import('./pages/AcessLevels/Bom/Salesman'));
const OfflineVendorPage = lazy(() => import('./pages/AcessLevels/Bom/OfflineVendorPage'));
const CorporateLeadsPage = lazy(() => import('./pages/AcessLevels/Bom/CorporateLeads'));
const CorporateMeetsPage = lazy(() => import('./pages/AcessLevels/Bom/Meetings'));
const SalesActivityPage = lazy(() => import('./pages/AcessLevels/Bom/SalesActivity'));
const LaborGroupPage = lazy(() => import('./pages/AcessLevels/Bom/LaborGroupPage'));
const RawMaterialIssue = lazy(() => import('./pages/AcessLevels/Bom/RawMaterialIssue'));
const CorporateSalePage = lazy(() => import('./pages/AcessLevels/Bom/CoporateSale'));
const CorporateClientPage = lazy(() => import('./pages/AcessLevels/Bom/CorporateClients'));
const SalesmanDashboard = lazy(() => import('./pages/AcessLevels/Salesman/SalesDashboard'));
const SalesmanActivity = lazy(() => import('./pages/AcessLevels/Salesman/SalesmenActivity'));
const SalesmanMeeting = lazy(() => import('./pages/AcessLevels/Salesman/SalesmenMeeting'));
const ProductionPoPage = lazy(() => import('./pages/AcessLevels/Bom/OpenPOPage'));
const PoDetailPage = lazy(() => import('./pages/AcessLevels/Bom/PODetailsPage'));
const SalesSchedulPage = lazy(() => import('./pages/AcessLevels/Salesman/SalesSchedule'));

function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize("UA-191521249-1");
      window.GA_INITIALIZED = true;
    }
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);
}


const App = () => {
  usePageViews()

  let location = useLocation();

  let history = useHistory();
  const dispatch = useDispatch();

  const timer = useAutoLogout(1000);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [history.location.pathname]);


  const [warning, setWarning] = useState(false)


  // to check firebase auth state
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const idTokenResult = await user.getIdTokenResult();

        currentUser(idTokenResult.token)
          .then((res) => {
            dispatch({
              type: "LOGGED_IN_USER",
              payload: {
                name: res.data.name,
                lastName: res.data.lastName,
                email: res.data.email,
                address: res.data.address,
                unitAddress: res.data.unitAddress,
                cityAddress: res.data.cityAddress,
                StateAddress: res.data.StateAddress,
                ZipAddress: res.data.ZipAddress,
                PhoneNumber: res.data.PhoneNumber,
                Points: res.data.Points,
                giftReceived: res.data.giftReceived,
                token: idTokenResult.token,
                role: res.data.role,
                _id: res.data._id,
                customCode: res.data.customCode,
                referralPoints: res.data.referralPoints,
                restricted: res.data.restricted,
                email2: res.data.email2,
                employee: res.data.employee,
                paymentIntent: res.data.paymentIntent,
                company: res.data.company,
                expoToken: res.data.expoToken,
                position: res.data.position
              },
            });

          })
          .catch((err) => console.log(err));
      }
    });
    // cleanup
    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {

    if (timer == 0) {
      setWarning(true)
    }
  }, [timer])


  return (

    <Suspense
      fallback={
        <div className="col text-center p-5">
          Optima
          <LoadingOutlined />
          Mart
        </div>
      }
    >

      {/* <Helmet>
      <title>Optima Mart</title>
      <meta name="description" content="Shop Groceries & Earn Rewards" />

      </Helmet> */}


      <HeaderNavigation />

      {!location.pathname.includes('/welcome') && (
        <Header />
      )}

      <SideDrawer />
      <CouponSideDrawer />

      <ToastContainer />

      {/* {window.ReactNativeWebView && ( */}
      <TabBar />
      {/* // )} */}

      <div tabIndex="-1" >

      </div>


      <Switch>
        {/* {(localStorage.getItem("DeliveryType") == 'Shipping') ? (
          <Route exact path="/" component={shippingHome} />
        ) : (
            <Route exact path="/" component={Home} />
          )} */}
        {/* <Route exact path="/" component={Home} />
        <Route exact path="/shipping-home" component={shippingHome} /> */}
        <Route exact path="/" component={Home} />
        <Route exact path="/bom/dashboard" component={BomDashboard} />
        <Route exact path="/bom/stock" component={BomStock} />
        <Route exact path="/bom/raw-material" component={BomRawmaterial} />
        <Route exact path="/bom/finished-material" component={BomFinishedMaterial} />
        <Route exact path="/bom/fixed-assets" component={FixedAssetsPage} />
        <Route exact path="/bom/sales-man" component={SalesmanPage} />
        <Route exact path="/bom/offline-vendor" component={OfflineVendorPage} />
        <Route exact path="/bom/corporate-leads" component={CorporateLeadsPage} />
        <Route exact path="/bom/corporate-meets" component={CorporateMeetsPage} />
        <Route exact path="/bom/sales-activity" component={SalesActivityPage} />
        <Route exact path="/bom/labor-group" component={LaborGroupPage} />
        <Route exact path="/bom/raw-material-issue" component={RawMaterialIssue} />
        <Route exact path="/bom/corporate-sale" component={CorporateSalePage} />
        <Route exact path="/bom/corporate-clients" component={CorporateClientPage} />
        <Route exact path="/salesman/dashboard" component={SalesmanDashboard} />
        <Route exact path="/salesman/sales-activity" component={SalesmanActivity} />
        <Route exact path="/salesman/sales-meeting" component={SalesmanMeeting} />
        <Route exact path="/bom/production-po" component={ProductionPoPage} />
        <Route exact path="/bom/po-details/:id" component={PoDetailPage} />
        <Route exact path="/salesman/sales-schedule" component={SalesSchedulPage} />

        {/* <Route exact path="/newhome" component={NewHome} /> */}
        <Route exact path="/login" component={Login} />
        <Route exact path="/register" component={Register} />
        <Route exact path="/all-categories" component={categoryAll} />
        <Route exact path="/welcome" component={Home} />
        <Route exact path="/diwali-gifting" component={DiwaliPage} />
        <Route exact path="/weekly-flyer" component={Flyer} />
        <Route exact path="/weekly-flyer/listings" component={FlyerListings} />
        <Route exact path="/legal/terms-of-service" component={Terms} />
        <Route exact path="/legal/return-policy" component={Return} />
        <Route exact path="/register/complete" component={RegisterComplete} />
        <Route exact path="/optima-rewards" component={RewardPage} />
        <Route exact path="/optima-contest" component={OptimaContest} />
        <Route exact path="/optima-referral" component={referralPage} />
        <Route exact path="/customer-support" component={customerSupport} />
        <Route exact path="/customer-refund" component={refundPage} />
        <Route exact path="/game-blackjack" component={blackjack} />
        <Route exact path="/optima-slots" component={slotGame} />
        {/* <Route exact path="/seller" component={seller} /> */}
        <Route exact path="/privacy-policy" component={privacy} />
        <Route exact path="/passport" component={passportView} />
        <Route exact path="/pages/business-delivery" component={businessDelivery} />
        <Route exact path="/pages/bulk-store" component={bulkStore} />
        <Route exact path="/forgot/password" component={ForgotPassword} />
        <UserRoute exact path="/user/history" component={History} />
        <UserRoute exact path="/user/password" component={Password} />
        <UserRoute exact path="/user/dashboard" component={Dashboard} />
        <UserRoute exact path="/user/wishlist" component={Wishlist} />
        <UserRoute exact path="/user/returns/add" component={userReturn} />
        <UserRoute exact path="/user/returns" component={userReturnList} />
        <Route exact path="/pages/game/mine" component={Mines} />
        <Route exact path="/pages/game/plinko" component={Plinko} />
        <Route exact path="/pages/game" component={GamePage} />

        {/* <UserRoute exact path="/user/payments/saved" component={CardSaved} /> */}

        <UserRoute exact path="/user/user-transaction" component={UserTransaction} />
        <Route exact path="/categories" component={Listing} />
        <AdminRoute exact path="/admin/dashboard" component={AdminDashboard} />
        <AccountantRoute exact path="/admin/supplier-add" component={supplierAdd} />
        <LogisticRoute exact path="/admin/supplier/:slug" component={supplierEdit} />
        <AdminRoute exact path="/admin/category" component={CategoryCreate} />
        <AdminRoute
          exact
          path="/admin/category/:slug"
          component={CategoryUpdate}
        />
        <AdminRoute exact path="/admin/variation" component={CreateVariation} />
        <AdminRoute exact path="/admin/variation/all" component={VariationList} />
        <AdminRoute exact path="/admin/variation/:id" component={VariationEdit} />
        <AdminRoute exact path="/admin/images" component={ImagePage} />
        <AdminRoute exact path="/admin/create-amazon" component={AmazonPage} />
        <AdminRoute exact path="/admin/update-amazon" component={AmazonPics} />

        <AdminRoute exact path="/admin/super-coupon" component={superCoupon} />
        <AdminRoute exact path="/admin/employee" component={employeePage} />
        <AdminRoute exact path="/admin/employee/:slug" component={employeeEdit} />
        <AdminRoute exact path="/admin/shipping-banner" component={shippingBanner} />
        <AdminRoute exact path="/admin/shipping-banner/:slug" component={shippingBannerUpdate} />
        <AdminRoute exact path="/admin/gift/:slug" component={giftUpdate} />
        <AdminRoute exact path="/admin/user/:id" component={CustomerManagement} />
        <AdminRoute exact path="/admin/brands" component={brandCreate} />
        <AdminRoute exact path="/admin/deals" component={dealCreate} />
        <AdminRoute exact path="/admin/deals/:slug" component={dealUpdate} />
        <AdminRoute exact path="/admin/returns/all" component={ReturnAdmin} />

        <AdminRoute exact path="/admin/banner" component={bannerCreate} />
        <AdminRoute exact path="/admin/create-banner" component={createBanner} />
        <AdminRoute exact path="/admin/sale" component={SalePage} />
        <AdminRoute exact path="/admin/location" component={LocationPage} />
        <AdminRoute exact path="/admin/brand-discount" component={brandDiscount} />
        <AdminRoute exact path="/admin/users" component={UsersPage} />
        <AdminRoute exact path="/admin/carts" component={CartsPage} />
        <AdminRoute exact path="/admin/offer" component={offerCreate} />
        <AdminRoute exact path="/admin/recipe-tag" component={tagCreate} />
        <AdminRoute exact path="/admin/sub" component={SubCreate} />
        <AdminRoute exact path="/admin/secondSub" component={SecondSubCreate} />
        <AdminRoute exact path="/admin/sub/:slug" component={SubUpdate} />
        <AdminRoute exact path="/admin/brand/:slug" component={brandUpdate1} />
        <AdminRoute exact path="/admin/country/:slug" component={CountryUpdate} />
        <LogisticRoute exact path="/admin/product" component={ProductCreate} />
        <AdminRoute exact path="/admin/gift" component={GiftCreate} />
        <AdminRoute exact path="/admin/super-coupon/:slug" component={superCouponAnalytics} />
        <AdminRoute exact path="/admin/create-country" component={countryCreate} />
        <AdminRoute exact path="/admin/products" component={AllProducts} />
        <AdminRoute exact path="/admin/filter/type" component={TypePage} />
        <AdminRoute exact path="/admin/filter/age" component={AgePage} />
        <AdminRoute exact path="/admin/filter/beauty-ingredients" component={BIngredientPage} />
        <AdminRoute exact path="/admin/filter/color" component={ColorPage} />
        <AdminRoute exact path="/admin/filter/diet" component={DietPage} />
        <AdminRoute exact path="/admin/filter/features" component={FeaturesPage} />
        <AdminRoute exact path="/admin/filter/for-use" component={ForUsePage} />
        <AdminRoute exact path="/admin/filter/gender" component={GenderPage} />
        <AdminRoute exact path="/admin/filter/hair-type" component={HairTypePage} />
        <AdminRoute exact path="/admin/filter/material" component={MaterialPage} />
        <AdminRoute exact path="/admin/filter/school-age" component={SchoolAgePage} />
        <AdminRoute exact path="/admin/filter/watch-display" component={WatchDisplayPage} />
        <AdminRoute exact path="/admin/filter/edit" component={FilterEdit} />
        <AdminRoute exact path="/admin/filter/case-color" component={CaseColorPage} />
        <AdminRoute exact path="/admin/filter/dial-color" component={DialColorPage} />
        <AdminRoute exact path="/admin/filter/dial-shape" component={DialShapePage} />
        <AdminRoute exact path="/admin/filter/movement" component={MovementPage} />
        <AdminRoute exact path="/admin/filter/occasion" component={OccasionPage} />
        <AdminRoute exact path="/admin/filter/strap-color" component={StrapColorPage} />
        <AdminRoute exact path="/admin/company-create" component={companyCreate} />
        <UserRoute exact path="/admin/mkt/integration" component={Integration} />
        <UserRoute exact path="/admin/mkt/instagram" component={Instagram} />
        <UserRoute exact path="/admin/mkt/integration-list" component={IntegrationList} />


        <AdminRoute
          exact
          path="/admin/product/:slug"
          component={ProductUpdate}
        />
        <AdminRoute
          exact
          path="/admin/order/:slug"
          component={OrderUpdate}
        />
        <Route exact path="/recipes" component={recipePage} />

        <Route exact path="/deal-of-the-day" component={dealPage} />
        <Route exact path="/product/:slug" component={Product} />
        <Route exact path="/plasma/list" component={Plasma} />
        <Route exact path="/category/:slug" component={CategoryHome} />
        <Route exact path="/bulk/category/:slug" component={bulkCategory} />
        <Route exact path="/sub/:slug" component={SubHome} />
        <Route exact path="/bulk/sub/:slug" component={bulkListing} />
        <Route exact path="/flyer/:slug" component={FlyerHome} />
        <Route exact path="/brand/:slug" component={BrandHome} />
        <Route exact path="/country/:slug" component={CountryHome} />
        <Route exact path="/shop" component={Shop} />
        <Route exact path="/cart" component={Cart} />
        <UserRoute exact path="/checkout" component={Checkout} />
        {/* <Route exact path="/guest-checkout" component={GuestCheckout} /> */}
        <Route exact path="/order/confirmation" component={Confirmation} />
        <AdminRoute exact path="/admin/coupon" component={CreateCouponPage} />
        <UserRoute exact path="/payment" component={Payment} />
        <Route exact path="/shop/:slug" component={ProduceAisle} />
        <Route exact path="/new-customer" component={NewCustomer} />
        <Route exact path="/optima-franchise" component={franchisePage} />
        <Route exact path="/about-us" component={AboutUs} />
        <Route exact path="/covid19" component={Covid19} />
        <Route exact path="/career" component={Career} />
        <Route exact path="/recipe-tag/:slug" component={recipeTagPage} />
        <Route exact path="/recipe-details/:slug" component={recipeItemDetail} />
        <AdminRoute exact path="/admin/all-recipes" component={recipeEdit} />
        <AdminRoute exact path="/admin/recipe/:slug" component={recipeUpdate} />
        <AdminRoute exact path="/admin/recipe-tag/:slug" component={recipeTagEditPage} />
        <AdminRoute exact path="/admin/create-store" component={storeCreate} />
        <AdminRoute exact path="/admin/edit-store/:slug" component={storeEdit} />
        <AdminRoute exact path="/admin/banner-update/:slug" component={bannerUpdate} />
        <AdminRoute exact path="/purchase/purcase-dashboard" component={DriverDashboard} />
        <AdminRoute exact path="/admin/bulk-uploader" component={bulkStock} />
        <Route exact path="/best-sellers" component={bestSellerPage} />
        <Route exact path="/super-coupons" component={superCouponHome} />
        <StoreChargeRoute exact path="/storecharge/dashboard" component={storeCharge} />
        <CashierRoute exact path="/storecharge/store-pos" component={StorePos} />
        <LogisticRoute exact path="/storecharge/product/product-list" component={StorePoductList} />
        <LogisticRoute exact path="/storecharge/product/add-product" component={StorePoductAdd} />
        <LogisticRoute exact path="/storecharge/purchase/add-purchase" component={StorePurchaseAdd} />
        <DriverRoute exact path="/driver/dashboard" component={driverPage} />
        <Route exact path="/supplier-diversity" component={supplier} />
        <AdminRoute exact path="/admin/notifications" component={notificationCreate} />
        <AdminRoute exact path="/storecharge/price-tag" component={priceTagCreate} />
        <AdminRoute exact path="/admin/ai/price" component={PiceAi} />
        <AdminRoute exact path="/admin/ai/products" component={ProductsAi} />
        <AdminRoute exact path="/admin/ai/dashboard" component={DashboardAi} />
        <AdminRoute exact path="/admin/tax-create" component={taxCreate} />
        <LogisticRoute exact path="/storecharge/purchase/all-purchase" component={storePurchaseList} />
        <LogisticRoute exact path="/storecharge/supplier/all-suppliers" component={StoreSupplierList} />
        <LogisticRoute exact path="/storecharge/supplier/supplier-create" component={StoreSupplierCreate} />
        <StoreChargeRoute exact path="/storecharge/store-orders" component={StoreOrders} />
        <StoreChargeRoute exact path="/storecharge/store-customers/find" component={findStoreCustomers} />
        <LogisticRoute exact path="/storecharge/store-inventory" component={StoreInventory} />
        <LogisticRoute exact path="/storecharge/purchase-order/create" component={PurchaseOrderCreate} />
        <LogisticRoute exact path="/storecharge/purchase-order/:slug" component={PurchaseOrderEdit} />
        <LogisticRoute exact path="/storecharge/transfer/create" component={TransferCreate} />
        <LogisticRoute exact path="/storecharge/transfer/list-all" component={TransferAllStore} />
        <LogisticRoute exact path="/storecharge/transfer/requests-all" component={TransferRequests} />
        <LogisticRoute exact path="/storecharge/transfer/:slug" component={TransferEdit} />
        <StoreChargeRoute exact path="/storecharge/order-edit/:slug" component={OrderEdit} />
        <CashierRoute exact path="/cashier/dashboard" component={CashierDashboard} />
        <LogisticRoute exact path="/logistic/dashboard" component={LogisticDashboard} />
        <LogisticRoute exact path="/logistic/supplier/:slug" component={SupplierDashboard} />
        <LogisticRoute exact path="/logistic/product/detail" component={ProductDetailDashboard} />
        <StoreChargeRoute exact path="/storecharge/sale-dashboard" component={SaleDashboard} />
        <StoreChargeRoute exact path="/storecharge/create-expense" component={CreateExpense} />
        <StoreChargeRoute exact path="/storecharge/all-expenses" component={ExpenseList} />
        <LogisticRoute exact path="/logistic/create-sale-return" component={CreateSaleReturn} />
        <LogisticRoute exact path="/logistic/create-purchase-return" component={CreatePurchaseReturn} />
        <LogisticRoute exact path="/logistic/get-returns" component={ReturnList} />
        <AccountantRoute exact path="/accounts/dashboard" component={AccountantDashboard} />
        <AccountantRoute exact path="/accounts/create-group" component={AccountantGroupCreate} />
        <AccountantRoute exact path="/accounts/create-sub" component={AccountantGroupSubCreate} />
        <AccountantRoute exact path="/accounts/voucher/bank-add" component={AccountantBankVoucher} />
        <AccountantRoute exact path="/accounts/gst" component={GstReport} />
        <AccountantRoute exact path="/accounts/gstr-2" component={Gstr2} />
        <AccountantRoute exact path="/accounts/gstr-3" component={Gstr3} />
        <AccountantRoute exact path="/accounts/ledger" component={LedgerPage} />
        <AccountantRoute exact path="/accounts/ledger-detail" component={LedgerDetail} />
        <AdminRoute exact path="/admin/create-blog" component={blogCreate} />
        <AdminRoute exact path="/admin/tag/:slug" component={TagUpdate} />
        <StoreChargeRoute exact path="/admin/inventory" component={inventoryDetail} />
        <StoreChargeRoute exact path="/admin/variety" component={varietyDetail} />
        <StoreChargeRoute exact path="/admin/pricing" component={PricingDetail} />
        <StoreChargeRoute exact path="/storecharge/placements" component={PlacementPage} />
        <StoreChargeRoute exact path="/storecharge/placements/sub" component={PlacementSub} />
        <StoreChargeRoute exact path="/storecharge/barcode-generator" component={pigeonHome} />
        <LogisticRoute exact path="/logistic/store-expiries" component={ExpiryDashboard} />
        <LogisticRoute exact path="/logistic/all-ledgers" component={AllLedgers} />
        <Route exact path="/discreet" component={discreetPage} />
        <LogisticRoute exact path="/store/business-pos" component={businessPos} />
        <Route exact path="/exhibition" component={ExhibitionPage} />
        <AdminRoute exact path="/exhibition-list" component={ExhibitionList} />
        <StoreChargeRoute exact path="/storecharge/inventory-health" component={Health} />
        <StoreChargeRoute exact path="/storecharge/price-health" component={Price} />
        <StoreChargeRoute exact path="/storecharge/brand-health" component={BrandPage} />
        <StoreChargeRoute exact path="/storecharge/brand-stock" component={BrandStock} />


        {/* <Route render={() => <Redirect to="/" />} /> */}
      </Switch>

      {window.ReactNativeWebView && (
        <>
          <br />
          <br />
          <br />
        </>
      )}

      {!window.ReactNativeWebView && (
        <Footer />
      )}

      <Modal
        title="Session Timeout Warning"
        centered
        visible={warning}
        closable={false}
        width={250}
        zIndex={10000}
        footer={[
          <Button onClick={() => { window.location.reload(); }} className="button btn button--prime button-width--med text-center" >
            Reload
          </Button>
        ]}
      >
        <div className="text-center">Page Session Time Out! Please Reload the Page</div>
      </Modal>


    </Suspense>

  );
};

export default App;
