import { combineReducers } from "redux";
import { userReducer } from "./userReducer";
import { searchReducer } from "./searchReducer";
import { cartReducer } from "./cartReducer";
import { drawerReducer } from "./drawerReducer";
import { couponReducer } from "./couponReducer";
import { CODReducer } from "./CODReducer";
import { CouponDrawerReducer } from "./CouponDrawerReducer"
import {SelectedCouponReducer} from "./SelectedCouponReducer"
import {cartBtnReducer} from "./cartBtnReducer"
import { suggestionReducer } from "./suggestionReducer";
import { superCouponReducer } from "./superCouponReducer";
import { paymentProcessorReducer } from './PaymentProcessor'
import {modalBtnReducer} from './modalbtnReducer'
import { cartPosReducer } from './posCartReducer'
import { giftReducer } from "./giftReducer";

const rootReducer = combineReducers({
  user: userReducer,
  search: searchReducer,
  cart: cartReducer,
  drawer: drawerReducer,
  coupon: couponReducer,
  COD: CODReducer,
  CouponDrawer: CouponDrawerReducer,
  selectedCoupon: SelectedCouponReducer,
  cartBtn: cartBtnReducer,
  suggestion: suggestionReducer,
  superCoupon: superCouponReducer,
  paymentProcessor: paymentProcessorReducer,
  modalBtn: modalBtnReducer,
  posCart: cartPosReducer,
  gifts: giftReducer
});

export default rootReducer;
