import axios from "axios";

export const createOrUpdateUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-or-update-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const createOrUpdateUserNumber = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/create-or-update-user-phone`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentUser = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-user`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentAdmin = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-admin`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentDriver = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-driver`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentStoreCharge = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-store-incharge`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentCashier = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-cashier`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentLogistic = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-logistic`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};

export const currentAccountant = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/current-accountant`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};


export const giftReceived = async (authtoken) => {
  return await axios.post(
    `${process.env.REACT_APP_API}/user/gift-received`,
    {},
    {
      headers: {
        authtoken,
      },
    }
  );
};