import React, { useEffect, useState, useRef } from 'react'
import moment from 'moment';
import { getNotifications } from '../../functions/notification'
import { Carousel, Divider, Button } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const HeaderNavigation = () => {

    const [dayHouse, setDayHours] = useState('00')
    const [timerHours, setTimerHours] = useState('00')
    const [timerMinutes, setTimerMinutes] = useState('00')
    const [timerSeconds, setTimerSeconds] = useState('00')
    const [notifications, setNotification] = useState([])

    let interval = useRef();

    const startTimer = () => {
        const countdownDate = new Date(`October 14, 2021 23:59:00`).getTime()


        interval = setInterval(() => {
            const now = new Date().getTime();

            const distance = countdownDate - now

            const days = Math.floor(distance / (1000 * 60 * 60 * 24));
            const hours = Math.floor((distance % (1000 * 60 * 60 * 24) / (1000 * 60 * 60)))
            const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
            const seconds = Math.floor((distance % (1000 * 60)) / 1000);

            if (distance < 0) {
                clearInterval(interval.current)
            } else {
                setDayHours(days);
                setTimerHours(hours);
                setTimerMinutes(minutes);
                setTimerSeconds(seconds)
            }

        }, 1000)
    }

    useEffect(() => {
        startTimer()

    }, []);

    useEffect(() => {
        loadNotifications();
    }, []);

    const loadNotifications = () =>
        getNotifications().then((c) => setNotification(c.data));

    const headerItemStyle = {
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '0 10px',
        color: '#ADFFA2', // Adjust the color to match your theme
        cursor: 'pointer',
    };
    const contentStyle = {
        height: '160px',
        color: '#fff',
        lineHeight: '160px',
        textAlign: 'center',
        background: '#364d79',
    };

    return (
        <div>
            {notifications && notifications.length > 0 && (
                <div className="row" style={{ marginRight: 0 }}>
                    <div className="title-bar-new">
                        <div className="col-md-12">
                            <Carousel autoplay={true} autoplaySpeed={5000} dots={false} effect='fade'>
                                {notifications.map((n) => (
                                    <div key={n._id} className="">
                                        <h3 className="title-bar_text"> {n.title} </h3>

                                    </div>
                                ))}
                            </Carousel>
                        </div>

                    </div>

                </div>
            )}
        </div>


    )
}

export default HeaderNavigation

