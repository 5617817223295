import axios from "axios";

export const getSubs = async () =>
  await axios.get(`${process.env.REACT_APP_API}/subs`);

export const getSub = async (slug, page, postPerPage) =>
  await axios.get(`${process.env.REACT_APP_API}/sub/${slug}?page=${page}&limit=${postPerPage}`);

export const getMiniSub = async (slug, delivery, store) =>
  await axios.get(`${process.env.REACT_APP_API}/sub/${slug}?type=mini&&delivery=${delivery}&&store=${store}`);

export const removeSub = async (slug, authtoken) =>
  await axios.delete(`${process.env.REACT_APP_API}/sub/${slug}`, {
    headers: {
      authtoken,
    },
  });

export const updateSub = async (slug, sub, authtoken) =>
  await axios.put(`${process.env.REACT_APP_API}/sub/${slug}`, sub, {
    headers: {
      authtoken,
    },
  });

export const createSub = async (sub, authtoken) =>
  await axios.post(`${process.env.REACT_APP_API}/sub`, sub, {
    headers: {
      authtoken,
    },
  });

export const getSubsSecond = async (_id) =>
  await axios.get(`${process.env.REACT_APP_API}/sub/secondSubs/${_id}`);

export const getSupProduct = async () =>
  await axios.get(`${process.env.REACT_APP_API}/sub/get-lowest/update`);
