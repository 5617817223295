let initialState = [];

// load cart items from local storage
if (typeof window !== "undefined") {
  if (localStorage.getItem("pos-cart")) {
    initialState = JSON.parse(localStorage.getItem("pos-cart"));
  } else {
    initialState = [];
  }
}

export const cartPosReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_POS_CART":
      return action.payload;
    default:
      return state;
  }
};
