let initialState = [];

export const giftReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_GIFT":
      return action.payload;
    default:
      return state;
  }
};
